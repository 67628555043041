<script setup lang="ts">
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthView } from '@base/types'

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const toast = useToast()
const { sendResetPasswordEmail } = useAuth()
const { emailValidation } = useValidations()

const validationSchema = yup.object({
  email: emailValidation,
})

const { meta, isSubmitting, handleSubmit } = useForm({ validationSchema })

const onSubmit = handleSubmit(async values => {
  const error = await sendResetPasswordEmail(values.email)
  if (error)
    return toast.error('Něco se pokazilo, zkuste to prosím později.')

  toast.success('Na email Vám byly zaslány instrukce pro obnovení hesla.')
  emit('close')
})
</script>

<template>
  <div>
    <form @submit="onSubmit">
      <div flex flex-col gap-16px>
        <h4 :data-test="getCypressId('forgot-password-header')" heading4 font-800 my-16px>
          Zapomenuté heslo
        </h4>
        <FInputVee name="email" type="text" label="Vaše e-mailová adresa" placeholder="Zadejte Váš e-mail" cypress-prefix="forgotten" />
      </div>
      <FButton name="forgot-password-continue" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        Pokračovat
      </FButton>
    </form>
    <div mt-32px paragraph-sm text-center>
      Pokud již máte vytvořený účet a znáte heslo, můžete se<br><button type="button" golden-link @click="emit('changeView', 'login')">
        Přihlásit
      </button>
    </div>
  </div>
</template>
